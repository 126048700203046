<template>
    <div>
        <div class="container" :class="$mq | mq({
          desktop: 'is-fluid',
        })">
          <div class="columns">
            <div class="column  jsmb-container-left" :class="$mq | mq({
                desktop: 'container is-6',
                wizard: 'is-8',
                laptop: 'is-8',
              })">
            <Navigation/>
              <div class="jsmb-container-9">
                <Title/>
                <StepOne @nextStep="switchStepsIncrement" v-if="step==0"/>
                <StepTwo @nextStep="switchStepsIncrement" @prevStep="switchStepsDecrement" v-if="step==1"/>
                <StepThree @nextStep="switchStepsIncrement" @prevStep="switchStepsDecrement" v-if="step==2"/>
                <StepFour @nextStep="switchStepsIncrement" @prevStep="switchStepsDecrement"  @backToStep2="backToStep2" v-if="step==3"/>
                <StepFive @nextStep="switchStepsIncrement" @prevStep="switchStepsDecrement" v-if="step==4"/>
              </div>
            </div>
            <div class="column is-4" :class="$mq | mq({
                desktop: 'jsmb-side',
                wizard: 'jsmb-side',
                laptop: 'jsmb-side',
                tablet: 'self-vcentered',
                mobile: 'self-vcentered',
              })">
              <SidebarOne :title="sidebarTitle" :subTitlesRed="sidebarSubTitleRed" :subTitles="sidebarSubTitle" :textContents="sidebarTextContent" v-if="step<4" />
            </div>
          </div>
        </div>
    </div>
</template>


<script>
import Navigation from '@/components/wizard/wizard-sections/Navigation.vue'
import Title from '@/components/wizard/wizard-sections/Title.vue'
import SidebarOne from '@/components/wizard/wizard-sections/SidebarOne.vue'
import StepOne from '@/components/wizard/wizard-sections/StepOne.vue'
import StepTwo from '@/components/wizard/wizard-sections/StepTwo.vue'
import StepThree from '@/components/wizard/wizard-sections/StepThree.vue'
import StepFour from '@/components/wizard/wizard-sections/StepFour.vue'
import StepFive from '@/components/wizard/wizard-sections/StepFive.vue'

export default {
  name: 'Wizard',
  components: {
    Navigation,
    Title,
    SidebarOne,
    StepOne,
    StepTwo,
    StepThree,
    StepFour,
    StepFive
  },
  data () {
    return {
      step: 0,
      sidebarTitle: '',
      sidebarSubTitle: [],
      sidebarSubTitleRed: [],
      sidebarTextContent: []
    }
  },
  updated () {
    this.resizeViewport()
  },
  mounted () {
    this.resizeViewport()
  },
  methods: {

    setStyle () {
      let myBody = document.querySelector('body').offsetWidth, 
        windowHeight = window.innerHeight,
        windowWidth = window.outerWidth,
        wizardWidth = document.querySelector('.jsmb-wizard').offsetWidth,
        navbarHeight = document.querySelector('.navbar').offsetHeight,
        side = document.querySelector('.jsmb-side'),
        
        containerLeft = document.querySelector('.jsmb-container-left').offsetHeight,
        
        diff = windowHeight - navbarHeight

      if (containerLeft < diff) {
        document.querySelector('.jsmb-container-left').style.minHeight = diff + "px";
      }

      if (side !== null) {
        side.style.top = ((diff/2)-(side.offsetHeight/2))+"px"
        // if(this.step === 3){
        //   side.style.top = ((side.offsetHeight)-(diff/2.5))+"px"
        // }
        if(window.innerWidth >= 1400){
          let decal = ((myBody-wizardWidth)/4)-(side.offsetWidth/2);
          side.style.right = decal + "px";
        }
      }

    },

    resizeViewport () {
      let the_timer,
          viewportWidth = window.innerWidth || document.documentElement.clientWidth;



      if(viewportWidth > 768){
        this.setStyle();
        clearTimeout(the_timer);
        the_timer = setTimeout(() => {
          window.addEventListener('resize', ()=> {
            this.setStyle()
          })
        }, 75);
      }
    },

    backToStep2 () {
      this.step = 1
      this.switchStepContent();
      setTimeout(() => {
        document.querySelector(".jsmb-type-espace").scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
        console.log(this.step);
        this.setStyle();
      }, 800);
    },
    switchStepContent () {
      switch(this.step) {
        case 0:
          this.sidebarTitle = 'Où est situé votre location ?'
          this.sidebarSubTitleRed = ''
          this.sidebarTextContent = ['Votre adresse exacte ne sera dévoilée aux Bateau-Skipper qu\'une fois la réservation confirmée.']
          break;
        case 1:
          this.sidebarTitle = 'type de location'
          this.sidebarSubTitleRed = ''
          this.sidebarSubTitle = ['Espace partagé','Espace privé']
          this.sidebarTextContent = ['Vous optez pour le costockage. Vous acceptez de partager votre espace pour que plusieurs Bateaux-Skippers stockent en même temps.','L\'espace est loué en totalité par un seul locataire.']
          break;
        case 2:
          this.sidebarTitle = 'Vos photos'
          this.sidebarSubTitleRed = ['Multipliez par 10']
          this.sidebarSubTitle = ['vos chances de louer votre emplacement en mettant des photos de votre espace !']
          this.sidebarTextContent = ['Au moins 3 photos sont recommandées.']
          break;
        case 3:
          this.sidebarTitle = 'Conditions'
          this.sidebarSubTitleRed = ''
          this.sidebarSubTitle = ['Sur RDV:','Remise des clés:']
          this.sidebarTextContent = ['L\'accès ne se fait qu\'en votre présence. Vous ne remettez donc pas les clés à votre locataire','Vous remettez les clés de l\'emplacement à votre locataire lors de la signature du contrat. Le locataire pourra y accéder seul.']
          break;
      }
    },
    switchStepsIncrement(){
      this.step++;
      this.switchStepContent();
      setTimeout(() => {
        document.querySelector(".jsmb-header-container").scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
        console.log(this.step);
        this.setStyle();
      }, 800);
    },
    switchStepsDecrement(){
      this.step--;
      this.switchStepContent();
      setTimeout(() => {
        document.querySelector(".jsmb-header-container").scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
        console.log(this.step);
        this.setStyle();
      }, 800);
    }
  },
  created() {
    this.switchStepContent(); 
  },
  metaInfo () {
    let siteName = this.$store.state.globalConstant.SITE_NAME,
      baseUrl = this.$store.state.globalConstant.BASE_URL,
      canonical = this.$router.resolve({ name: 'Wizard' });

    let title = (siteName+' - Déposer mon Annonce - Stockage de bateau chez les particuliers et professionnels - Hivernage Bateau - stockage bateau hors d\'eau'),
      description = (siteName+' est le spécialiste du stockage de bateaux chez les particuliers et professionnels. Déposez votre annonce concernant la recherche d\'un bateau-skipper ou d\'un bateau-stockeur, pour l\'hivernage, stockage de votre bateau.');

    return {
      // if no subcomponents specify a metaInfo.title, this title will be used
      titleTemplate: title,
      link: [
        { rel: 'canonical', href: (baseUrl+canonical.href) },
      ],
      meta: [
        { 
          vmid: 'description', 
          name: 'description', 
          content: description 
        },
        {
          'property': 'og:title',
          'content': title,
          'vmid': 'og:title'
        },
        {
          'property': 'og:description',
          'content': description
        },
        {
          'property': 'og:url',
          'content': (baseUrl+this.$route.fullPath),
          'vmid': 'og:url'
        },
        {
          'property': 'og:image',
          'content': (baseUrl+require('./../../assets/og-image.png')),
          'vmid': 'og:image'
        },
        {
          'property': 'og:image:secure_url',
          'content': (baseUrl+require('./../../assets/og-image.png')),
          'vmid': 'og:image:secure_url'
        },
        {
          'property': 'twitter:title',
          'content': title,
          'vmid': 'twitter:title'
        },
        {
          'property': 'twitter:description',
          'content': description,
          'vmid': 'twitter:description'
        },
        {
          'property': 'og:image:alt',
          'content': title,
          'vmid': 'og:image:alt'
        },
        {
          'property': 'twitter:image',
          'content': (baseUrl+require('./../../assets/tw-card.jpg')),
          'vmid': 'twitter:image'
        },
      ]
    }
  },
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="css">
  .container.is-fluid {
    margin-right: 0;
    margin-left: 0;
  }
  .jsmb-container-9 {
      background: #f2f5fb;
      position: relative;
      padding:1rem;
      height: 100%;
  }
  .jsmb-container-9:before {
      content: "";
      position: absolute;
      left: -100%;
      background: #f2f5fb;
      width: 100%;
      height: 100%;
      top: 0;
  }

  @media screen and (min-width:1400px){
    .jsmb-side,
    .self-vcentered {
      position: absolute;
      max-width: 360px;
      right: 0;
      top:calc(50vh - 112px);
    }
  }

  @media screen and (max-width:1399px){
    .jsmb-side,
    .self-vcentered {
      position: absolute;
      max-width: 360px;
      right: 0 !important;
      margin-left:2rem;
      top:calc(50vh - 112px);
    }
    .jsmb-container-left {
      background: #f2f5fb;
      position: relative;
      max-width: 65vw;
    }
  }


  @media screen and (max-width:769px){
    .jsmb-container-left {
      max-width: inherit;
      left:inherit;
    }
    
    .self-vcentered {
      position:relative;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      position: relative;
      max-width: inherit;
      top: 0 !important;
      right:25px !important;
    }
   }




  

</style>
<!-- CSS global -->
<style>
  .jsmb-wizard  ul.is-uppercase li a {
    cursor: default;
  }
</style>
