<template>
    <div>
      <div class="notification is-info" v-if="newAccount">
        Merci ! Votre compte a bien été créé ! Vous pouvez désormais utiliser la plateforme JeStockeMonBateau !
      </div>
      
      <h2 class="is-uppercase is-size-4">informations générales</h2>
      <!-- BEGIN FORM -->
      <form @submit.prevent="nextStep">
        <!-- BEGIN SECTION 1 -->
        <h3 class="is-uppercase has-text-weight-bold has-text-dark">Où est situé votre location ?</h3>
        <!-- <div class="field">
          <div class="control">
            <vue-google-autocomplete
              ref="google_maps_address"
              id="google_maps"
              classname="input"
              placeholder="Saisir votre adresse"
              v-on:placechanged="getAddressData"
              :country="country"
              :enable-geolocation="true"
            />
            <p class="help is-danger has-margin-bottom-1" :class="{'is-hidden': notError.address}">{{errorMsg.address}}</p>
          </div>
        </div>

        <br>

        <h3 class="is-uppercase has-text-weight-bold has-text-dark">Complétez votre adresse</h3> -->
        <div class="field">
          <label class="label">Numéro et nom de la rue <sup class="has-text-danger has-text-weight-bold">*</sup></label>
          <div class="control">
            <input class="input" type="text" placeholder="Votre adresse" v-model="stepOne.address" required>
          </div>
        </div>
        <div class="field">
          <label class="label">Appartement, bâtiment (facultatif)</label>
          <div class="control">
            <input class="input" type="text" placeholder="Saisir votre complément d'adresse" v-model="stepOne.address_complement">
          </div>
        </div>
        <div class="columns">
          <div class="column is-3">
            <div class="field">
              <label class="label">Code postal <sup class="has-text-danger has-text-weight-bold">*</sup></label>
              <div class="control">
                <input class="input" type="text" placeholder="Votre CP" v-model="stepOne.zip_code" required>
              </div>
            </div>
          </div>
          <div class="column is-3 jsmb-add-padding-left">
            <div class="field">
              <label class="label">Ville <sup class="has-text-danger has-text-weight-bold">*</sup></label>
              <div class="control">
                <input class="input" type="text" placeholder="Votre ville" v-model="stepOne.city" required>
              </div>
            </div>
          </div>
        </div>

        <br>

        <div v-if="stepOne.latitude !== '' && stepOne.longitude !== ''">
          <h3 class="is-uppercase has-text-weight-bold has-text-dark">Si l'emplacement n'est pas correct, merci de deplacer le curseur à l'endroit exact de votre adresse.</h3>
          <gmap-map
            :center="center"
            :zoom="17"
            style="width:100%; height:400px;"
            ref="map"
            :options="{
                zoomControl: true,
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: false,
                disableDefaultUi: true
            }"
          >
            <gmap-marker :position="{ lat: Number(stepOne.latitude), lng: Number(stepOne.longitude) }" :draggable="true" @dragend="markerDragEnd"/>
          </gmap-map>
        </div>

        <br>

        <!-- BEGIN SECTION 2 -->
        <h3 class="is-uppercase has-text-weight-bold has-text-dark">titre de l’annonce <sup class="has-text-danger has-text-weight-bold">*</sup></h3>
        <div class="field">
          <div class="control">
            <input class="input" type="text" placeholder="Saisir le titre de votre annonce" v-model="stepOne.title_announcement" required>
          </div>
        </div>

        <br>

        <!-- BEGIN SECTION 3 -->
        <h3 class="is-uppercase has-text-weight-bold has-text-dark">description <sup class="has-text-danger has-text-weight-bold">*</sup></h3>
        <div class="field">
          <div class="control">
              <textarea
                style="background:transparent"
                class="textarea"
                v-model="stepOne.content_announcement"
                placeholder="Je possède un terrain accessible par un chemin privé. Sécurisé par un portail fermé + caméras plus de 150 m². Disponible à partir d'octobre 30 km de la mer mediterrannée."
                required>
              </textarea>
            </div>
        </div>

        <!-- <h3 class="is-uppercase has-text-weight-bold has-text-dark">emplacement</h3>
        <div class="field">
          <div class="control">
              <textarea
                style="background:transparent"
                class="textarea"
                v-model="stepOne.emplacement"
                placeholder="Place de 40m² sur un terrain partagé avec 3 autres places. Place isolée, Sol gravilloné, Abrité / Non abritée, Place située à l'entrée du terrain.">
              </textarea>
            </div>
        </div> -->

        <!-- BEGIN SECTION 4 -->
        <div class="columns is-multiline stepOne-section-3">
          <div class="column is-12">
            <h4 class="is-size-6 is-uppercase has-text-weight-bold has-text-dark">Sélectionnez au moins une case <sup class="has-text-danger has-text-weight-bold">*</sup></h4>
          </div>

          <div class="column is-6">
            <div class="control">
              <label class="checkbox">
                <input type="checkbox" id="boat_trailer" value="1" v-model="stepOne.boat_trailer">
                J’accepte de stocker BATEAU + REMORQUE
              </label>
            </div>
            <div class="control">
              <label class="checkbox">
                <input type="checkbox" id="trailer_only" value="1" v-model="stepOne.trailer_only">
                J’accepte de stocker UNIQUEMENT LA REMORQUE
              </label>
            </div>
          </div>

          <div class="column is-6">
            <div class="control">
              <label class="checkbox">
                <input type="checkbox" id="tugboat" value="1" v-model="stepOne.tugboat">
                <span class="has-text-weight-bold is-uppercase">&nbsp;Je suis un BATEAU-REMORQUEUR</span><br>
                Je possède le permis remorque & je propose mes services (prix à la mise à l’eau)
              </label>
            </div>
          </div>
          <p class="help is-danger has-margin-bottom-1" :class="{'is-hidden': notError.checkbox}">{{errorMsg.checkbox}}</p>
        </div>

        <br>

        <div class="has-text-danger">
          <small><sup class="has-text-danger has-text-weight-bold">*</sup>Champs obligatoires</small>
        </div>

        <!-- BEGIN FOOTER 1 -->
        <div class="has-text-right">
          <button class="button is-danger" type='submit'>Étape suivante</button>
        </div>
      </form>
    </div>
</template>
<script>
import VueGoogleAutocomplete from 'vue-google-autocomplete'
import { setTimeout } from 'timers';

export default {
  name: 'StepOne',
  components: { VueGoogleAutocomplete },

  data () {
    return {
      notError: {
        address: true,
        checkbox: true,
      },
      errorMsg: {
        address: '',
        checkbox: '',
      },

      newAccount: false,

      pageLoading: false,
      center: { lat: 43.2961743, lng: 5.3699525 },

      stepOne: {
        address: '',
        address_complement: '',
        zip_code: '',
        city: '',
        country: '',
        longitude: '',
        latitude: '',
        title_announcement: '',
        content_announcement: '',
        emplacement: '',
        trailer_only: false,
        boat_trailer: false,
        tugboat: false,
      },


      country: []//['FR', 'GP', 'MQ', 'GF', 'RE', 'YT', 'PM', 'BL', 'MF', 'WF', 'PF', 'NC']
    }
  },
  methods: {
    /**
     * When the location found
     * @param {Object} addressData Data of the found location
     * @param {Object} placeResultData PlaceResult object
     * @param {String} id Input container ID
     */
    getAddressData: function (addressData, placeResultData, id) {
      this.stepOne.address = addressData.street_number+' '+addressData.route
      this.stepOne.zip_code = addressData.postal_code
      this.stepOne.city = addressData.locality
      this.stepOne.country = addressData.country
      this.stepOne.longitude = addressData.longitude
      this.stepOne.latitude = addressData.latitude
    },

    nextStep() {
      if (this.stepOne.address === '') {
        this.notError = {address: true, checkbox: true}
        this.errorMsg = {address: '', checkbox: ''}

        this.notError.address = false
        this.errorMsg.address = 'Veuillez saisir votre adresse.'
        document.getElementById("google_maps").scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
      }else if (this.stepOne.trailer_only === false && this.stepOne.boat_trailer === false && this.stepOne.tugboat === false) {
        this.notError = {address: true, checkbox: true}
        this.errorMsg = {address: '', checkbox: ''}
        
        this.notError.checkbox = false
        this.errorMsg.checkbox = 'Sélectionnez au moins une case'
      }else{
        this.notError = {address: true, checkbox: true}
        this.errorMsg = {address: '', checkbox: ''}
        localStorage.setItem('stepOne', JSON.stringify(this.stepOne))
        this.$emit('nextStep')
      }
    },

    getGeocode () {
      var geocoder = new google.maps.Geocoder()
      geocoder.geocode( { "address": this.stepOne.address+' '+this.stepOne.address_complement+' '+this.stepOne.zip_code+' '+this.stepOne.city }, (results, status) => {
        if (status == google.maps.GeocoderStatus.OK && results.length > 0) {
          this.center = {
            lat: results[0].geometry.location.lat(),
            lng: results[0].geometry.location.lng()
          }
          this.stepOne.latitude = results[0].geometry.location.lat()
          this.stepOne.longitude = results[0].geometry.location.lng()
        }
      })
    },

    markerDragEnd(marker) {
      this.stepOne.latitude = marker.latLng.lat()
      this.stepOne.longitude = marker.latLng.lng()
    }
  },
  created () {
    this.pageLoading = true
    window.vueGoogleMapsInit(google)
    if (localStorage.getItem('stepOne') !== null) {
      this.stepOne = JSON.parse(localStorage.getItem('stepOne'))
      this.center = { lat: Number(this.stepOne.latitude), lng: Number(this.stepOne.longitude) }
    }
    if (localStorage.getItem('modalCurrentStep') !== null) {
      localStorage.removeItem('modalCurrentStep')
      this.newAccount = true
      setTimeout(() => {
        this.newAccount = false
      }, 5000);
    }
  },
  mounted () {
    setTimeout(() => {
      this.pageLoading = false
    }, 1000);
     if(localStorage.getItem('stepOne')) {
      this.stepOne = JSON.parse(localStorage.getItem('stepOne'))
    }
  },
  watch: {
    'stepOne.address': {
      handler: function(newValue, oldValue) {
        if (!this.pageLoading && newValue.address !== '' && newValue.zip_code !== '' && newValue.city !== '') {
          this.getGeocode()
        }
      },
      deep: true
    },
    'stepOne.address_complement': {
      handler: function(newValue, oldValue) {
        if (!this.pageLoading && newValue.address !== '' && newValue.zip_code !== '' && newValue.city !== '') {
          this.getGeocode()
        }
      },
      deep: true
    },
    'stepOne.zip_code': {
      handler: function(newValue, oldValue) {
        if (!this.pageLoading && newValue.address !== '' && newValue.zip_code !== '' && newValue.city !== '') {
          this.getGeocode()
        }
      },
      deep: true
    },
    'stepOne.city': {
      handler: function(newValue, oldValue) {
        if (!this.pageLoading && newValue.address !== '' && newValue.zip_code !== '' && newValue.city !== '') {
          this.getGeocode()
        }
      },
      deep: true
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="css">
  h2 {
    font-weight: bold;
    margin-bottom: 3rem;
  }
  h3 {
    margin-bottom: 1rem;
  }
  div[class^='jsmb-type']{
      margin-bottom: 3rem;
  }
  input:not([type="radio"]),
  input:not([type="checkbox"]){
    margin-bottom: 0rem;
    border:none;
    background: transparent;
    border:1px solid #c7c9ce
  }
  textarea.textarea{
    background-color: transparent !important;
  }
  input[type=radio],
  input[type=checkbox] {
    border: 1px solid #df5051;
    padding: 0.5em;
    -webkit-appearance: none;
  }
  input[type=radio]:checked, 
  input[type=checkbox]:checked {
    background-size: 9px 9px;
    background-color: #df5051;
  }
  input[type=radio]:focus,
  input[type=checkbox]:focus {
    outline-color: none;
  }
  .radio,
  .checkbox {
    font-size:0.85rem
  }
  .is-horizontal-center {
    margin: 0 auto;
  }
  label {
    font-size: 1rem;
    font-weight: 100;
  }
  .jsmb-add-padding-left {
    padding-left: 1rem;
  }
  @media screen and (max-width:768px){
    .jsmb-add-padding-left {
      padding-left: 0;
    }    
  }
  .stepOne-section-3 .control {
    margin: 1rem 0
  }
</style>