<template>
    <div class="jsmb-header-container">
        <div class="container">
            <div class="columns">
                <div class="column">
                    <h1 class="is-size-3 is-uppercase">Déposez votre annonce</h1>
                    <Separator class="image is-48x48" color="primary" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import Separator from '@/components/template/ui/Separator.vue'


export default {
    name:'Title',
    components: {Separator},
    
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="css">
h1 {
    font-weight: 100;
}

</style>