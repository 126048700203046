<template>
    <div class="jsmb-sidebar-container">
        <h2 class="is-size-5 is-uppercase has-text-grey has-text-weight-bold">Vous pourrez modifier votre annonce depuis votre compte > mes annonces</h2>
        <div class="sidebar-info">
            <h3 class="is-size-5 is-uppercase has-text-grey has-text-weight-bold">{{title}}</h3>
            <Separator class="push-bottom image is-32x32 jsmb-svg" color="primary" />
             
            <strong><span v-if="subTitlesRed.length > 0" style="color: #df5051;">{{ subTitlesRed[0] }}</span> {{ subTitles[0] }}</strong>
            <p>{{ textContents[0] }}</p>
            <strong v-if="subTitles.length > 0">{{ subTitles[1] }}</strong>
            <p v-if="subTitles.length > 0">{{ textContents[1] }}</p>
            <!-- </div> -->
        </div>
    </div>
</template>

<script>
import Separator from '@/components/template/ui/Separator.vue'

export default {
  name: 'SidebarOne',
  components: {Separator},

  props:{
      title:{
          type:String
      },
      subTitles:{
          type:Array
      },
      subTitlesRed:{
          type:Array
      },
      textContents:{
          type:Array
      }
  },

  data () {
    return {
      
    }
  }
}

</script>


<style scoped lang="css">
.jsmb-sidebar-container {
  right: 0;
  float: left;
  padding:1rem;
}
.jsmb-sidebar-container>h2 {
    margin-bottom: 1.5rem;
}
.sidebar-info {
    padding: 1.1vw;
    background: #f2f5fb;
}
strong+p {
    margin-bottom: 1.5rem;
}
</style>
