<template>
    <div>

      <h3 class="is-size-3 is-uppercase has-text-weight-bold">Félicitations !</h3>
      <h3 class="is-uppercase has-text-weight-bold is-size-4">Votre annonce a été envoyée</h3>
      Celle-ci sera visible dès qu'elle aura été passée en revue par notre équipe de modération
        
      <br>
      <br>
      <!-- BEGIN FOOTER 1 -->
      <div class="columns">
        <div class="column is-6 has-text-right-mobile has-text-right-tablet">
          <router-link to="/" tag="a" class="button is-primary" exact>Retourner à l'accueil</router-link>
        </div>
      </div>

    </div>
</template>
<script>

export default {
  name: 'StepFive',
  created () {
    this.$analytics.fbq.event('CompleteRegistration')
   },
  mounted() {
    localStorage.removeItem('stepOne')
    localStorage.removeItem('stepTwo')
    localStorage.removeItem('stepThree')
    localStorage.removeItem('stepFour')
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="css">
  div[class^='jsmb-type']{
      margin-bottom: 3rem;
  }
  h2 {
      font-weight: bold;
      margin-bottom: 3rem;
  }
  h3 {
    margin-bottom: 1rem;
  }
  input[type=radio],
  input[type=checkbox] {
    border: 1px solid #df5051;
    padding: 0.5em;
    -webkit-appearance: none;
  }
  input[type=radio]:checked,
  input[type=checkbox]:checked {
    background-size: 9px 9px;
    background-color: #df5051;
  }
  input[type=radio]:focus,
  input[type=checkbox]:focus {
    outline-color: none;
  }
  .checkbox,
  .radio {
    font-size:0.85rem;
    margin-bottom: 0.5rem;
  }
  .jmsb-place-container {
    margin-bottom: 2.5rem;
  }
  .jsmb-place {
    background:#fff;
    padding: 1.5rem 1rem;
    margin-bottom: 1rem;
  }
  .has-text-underline {
    text-decoration: underline;
  }
  .place-input {
    width: 5rem;
    text-align: center;
    background-color: white;
    border:1px solid #dbdbdb;
    color: #363636;
    width: max-content;
    border-radius: 4px;
    padding: 0.5rem 1.5rem;
    position: relative;
  }
  .place-input:after {
    content:"€/m²/jour";
    display: block;
    position: absolute;
    right:-4rem;
    top:50%;
    transform: translateY(-50%);
    font-size: 0.8rem
  }
  .push-bottom {
    margin-top: auto;
  }
  .jsmb-type-conditions label {
    font-weight: normal;
  }
  input[type="tel"]{
    background: transparent;
    box-shadow: none;
  }
</style>

<style  lang="css">
  .jmsb-annonce-photo-container .upload .upload-draggable {
    border: 0px !important;
  }
  .jmsb-annonce-photo-container .has-addons {
    display:flex;
    flex-direction:column;
    align-items:center;
  }
  .jmsb-annonce-photo-container .file-name {
    border-width:0px;
    border-radius:3px;
    font-size:0.8rem;
    color:#fff;
    width:8rem;
    text-align: center;
    background:#14acc2;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .jmsb-annonce-photo-container .field.has-addons .control:first-child .button {
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
  }
</style>
