var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"container",class:_vm._f("mq")(_vm.$mq,{
      desktop: 'is-fluid',
    })},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column  jsmb-container-left",class:_vm._f("mq")(_vm.$mq,{
            desktop: 'container is-6',
            wizard: 'is-8',
            laptop: 'is-8',
          })},[_c('Navigation'),_c('div',{staticClass:"jsmb-container-9"},[_c('Title'),(_vm.step==0)?_c('StepOne',{on:{"nextStep":_vm.switchStepsIncrement}}):_vm._e(),(_vm.step==1)?_c('StepTwo',{on:{"nextStep":_vm.switchStepsIncrement,"prevStep":_vm.switchStepsDecrement}}):_vm._e(),(_vm.step==2)?_c('StepThree',{on:{"nextStep":_vm.switchStepsIncrement,"prevStep":_vm.switchStepsDecrement}}):_vm._e(),(_vm.step==3)?_c('StepFour',{on:{"nextStep":_vm.switchStepsIncrement,"prevStep":_vm.switchStepsDecrement,"backToStep2":_vm.backToStep2}}):_vm._e(),(_vm.step==4)?_c('StepFive',{on:{"nextStep":_vm.switchStepsIncrement,"prevStep":_vm.switchStepsDecrement}}):_vm._e()],1)],1),_c('div',{staticClass:"column is-4",class:_vm._f("mq")(_vm.$mq,{
            desktop: 'jsmb-side',
            wizard: 'jsmb-side',
            laptop: 'jsmb-side',
            tablet: 'self-vcentered',
            mobile: 'self-vcentered',
          })},[(_vm.step<4)?_c('SidebarOne',{attrs:{"title":_vm.sidebarTitle,"subTitlesRed":_vm.sidebarSubTitleRed,"subTitles":_vm.sidebarSubTitle,"textContents":_vm.sidebarTextContent}}):_vm._e()],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }