<template>
    <div>
      <h2 class="is-uppercase is-size-4">conditions</h2>
      <!-- BEGIN FORM -->
      <form @submit.prevent="nextStep">
        <!-- BEGIN SECTION 1 -->
        <div class="jsmb-type-conditions columns ">
          <div class="column is-6">
            <h3 class="has-text-dark is-uppercase has-text-weight-bold">accès du bateau-skipper<sup class="has-text-danger has-text-weight-bold">*</sup></h3>
            <div class="columns" style="position:relative" v-if="isLoadingAccessTypes">
              <div class="column is-12" style="min-height:50px">
                <b-loading class="rmvBackground"  :is-full-page="false" :active.sync="isLoadingAccessTypes"></b-loading>
              </div>
            </div>
            <div class="columns is-multiline">
              <div class="column is-12" v-for="(accessType, index) in accessTypes" :key="'access-type-'+index">
                <label class="radio">
                  <input type="radio" name="accessType" :id="accessType.id" :value="accessType.id" v-model="stepFour.accessType" required/>
                  {{accessType.name}}
                </label>
              </div>
            </div>
          </div>
        </div>
        
        <!-- BEGIN SECTION 2 -->
        <div class="jsmb-type-option">
          <h3 class="has-text-dark is-uppercase has-text-weight-bold">entrez votre tarif<sup class="has-text-danger has-text-weight-bold">*</sup></h3>
          <div class="jmsb-place-container"  v-for="(space, index) in spaces" :key="'space-'+index">
            <div class="columns jsmb-place ">
              <div class="column is-12 ">
                <div class="columns is-multiline is-mobile is-vcentered">
                  
                  <div class="column is-3-desktop is-12-mobile is-12-tablet">
                    <h4 class="is-size-4 has-text-weight-bold is-uppercase has-text-primary has-text-centered-mobile has-text-centered-tablet">
                      {{ space.title }}
                    </h4>
                  </div>

                  <div class="column is-2-desktop is-12-mobile is-12-tablet centror">
                    <!-- <p class="place-input">
                      <input type="number" min="0.50" step="0.50" v-model="space.priceUser" :name="'price-'+index" :id="'price-'+index" required/>
                    </p> -->
                    <div class="field place-input" style="display:flex">
                      <button type="button" class="button is-primary rmvm br" @click="changePrice(space, 'sub')" :disabled="space.priceUser<=0.50?true:false">–</button>
                      <input class="" type="number" min="0.01" step="0.01" v-model="space.priceUser" :name="'price-'+index" :id="'price-'+index" required/>
                      <button type="button" class="button is-primary rmvm bl" @click="changePrice(space, 'add')">+</button>
                    </div>
                  </div>
                  <div class="column is-7-desktop is-12-mobile is-12-tablet has-text-centered-mobile has-text-right-desktop has-text-centered-tablet">
                    <div class="columns is-multiline jsmb-reservation-prix">
                      <div class="column is-12">
                        <p>soit : {{ showPrice("day", index, space.priceUser, space.length, space.width) }} / jour</p>
                      </div>
                      <div class="column is-12">
                        <p>soit : {{ showPrice("month", index, space.price, space.length, space.width) }} / mois</p>
                      </div>
                      <div class="column is-12">
                        <small>(tarifs avec options)</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="columns is-vcentered is-multiline">
              <!-- <div class="column is-5">
                <h4 class="is-size-5 has-text-weight-bold is-uppercase has-text-text" v-if="nearest_harbor">distance du 1er port : {{nearest_harbor}}km</h4>
                <h4 class="is-size-5 has-text-weight-bold is-uppercase has-text-text" v-if="nearest_hold">distance de la cale mise à l’eau : {{nearest_hold}}km</h4>
              </div> -->
              <div class="column is-8-desktop is-8-tablet push-bottom">
                <h4 class="is-size-6-desktop is-size-7-tablet is-size-7-mobile has-text-centered-mobile has-text-weight-bold is-uppercase has-text-primary" v-if="space.priceMonthRecommended">prix recommandé : {{ space.priceDayRecommended }} / jour	soit {{ space.priceMonthRecommended }} / mois</h4>
              </div>
              <br>
              <div class="column is-4-desktop has-text-right-desktop has-text-right-tablet has-text-centered-mobile">
                <a @click="returnToStep2()" class="button is-primary has-text-white is-small ">Modifier les options</a>
              </div>
            </div>
          </div>
        </div>

        <!-- BEGIN FOOTER 1 -->
        <div class="columns ">
          <div class="column is-6 has-text-centered-mobile has-text-left-desktop" style="margin-bottom:1rem">
            <button class="button is-danger-invert" type="button" @click="prevStep">Étape précédente</button>
          </div>
          <div class="column is-6 has-text-centered-mobile has-text-right-tablet">
            <button type="submit" class="button is-danger" :class="buttonDoneLoad?'is-loading':''" :disabled="buttonDoneLoad?true:false">Terminer</button>
          </div>
        </div>
      </form>
    </div>
</template>
<script>
import { GET_ACCESS_TYPE, GET_SETTING, GET_CALE, ADD_ANNOUNCEMENT, GET_USER, MAIL_FOR_INFORMATION_USER } from '@/store/modules/database/actions/database.actions'

export default {
  name: 'StepFour',
  data () {
    return {
      accessTypes: [],
      isLoadingAccessTypes: false,
      spaces: [],
      nearest_harbor: 0,
      nearest_hold: 0,
      commissionPercentageJSMB: 0,
      user_birthday_date:"",
      user_iban:"",
      buttonDoneLoad: false,
      user: {
        first_name: '',
        last_name: '',
        company: '',
        tax_id: '',
        vat_id: '',
        user_gender_id: undefined,
        user_type_id: undefined,
        account_type_id: undefined,
        full_feature: false,
        email: '',
        dob: undefined,
        phone: '',
        phoneWithout33: '',
        billing_address: '',
        billing_address_complement: '',
        billing_zip_code: '',
        billing_city: '',
        billing_country: '',
        iban_stockeur: '',
        bio: '',
        new_profil_picture: false,
        profil_picture_64: require('./../../../assets/profil-modal.png'),
      },

      stepFour: {
        accessType: null
      }
    }
  },
  methods: {
    nextStep() {
      var stepOne = JSON.parse(localStorage.getItem('stepOne'))
      var stepTwo = JSON.parse(localStorage.getItem('stepTwo'))
      var stepThree = JSON.parse(localStorage.getItem('stepThree'))

      stepTwo.spaces = this.spaces;
      localStorage.setItem('stepTwo', JSON.stringify(stepTwo))
      localStorage.setItem('stepFour', JSON.stringify(this.stepFour))

      this.buttonDoneLoad = true

      this.$store.dispatch(
        ADD_ANNOUNCEMENT,
        {
          stepOne: stepOne,
          stepTwo: stepTwo,
          stepThree: stepThree,
          stepFour: this.stepFour,
          user_id: localStorage.getItem('user-id')
        }
      ).then(
        () => {
          this.buttonDoneLoad = false
          
          if(this.user.iban_stockeur == null || this.user.dob == null){
            this.sendEmail();
          }
          this.$emit('nextStep');
        }
      )
    },
    sendEmail(){
      
      let userData = JSON.parse(JSON.stringify(this.user))
      var  email = userData.email 
      var id_user = userData.id
      var token =  localStorage.getItem('token') 

      this.$store.dispatch(
        MAIL_FOR_INFORMATION_USER,
        {
          id_user:id_user,
          email:email,
          token:token
        }
      ).then(
        resp => {
          
        }
      ).catch(function(error) {
  			console.error(error);
		});
    },
    prevStep() {
      this.$emit('prevStep');
    },
    returnToStep2() {
      this.$emit('backToStep2')
    },
    showPrice(periode, index) {
      let valueCommissionJSMB = parseFloat(this.spaces[index].priceUser) * this.commissionPercentageJSMB / 100,
          price = parseFloat(this.spaces[index].priceUser) + parseFloat(valueCommissionJSMB)
      switch (periode) {
        case 'day':
          this.spaces[index].price = price
          return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(price)
      
        case 'month':
          return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(price * 30)
      }
    },
    changePrice(space, action) {
      switch (action) {
        case 'add':
          space.priceUser = Math.round((parseFloat(space.priceUser) + 0.50) * 100) / 100
          break;
      
        case 'sub':
          if (space.priceUser > 0.50) {
            space.priceUser = Math.round((parseFloat(space.priceUser) - 0.50) * 100) / 100
          }
          break;
      }
    }
  },
  created() {
    this.isLoadingAccessTypes = true
    // Get LocationTypes
    this.$store.dispatch(
      GET_ACCESS_TYPE
    ).then(
      resp => {
        this.isLoadingAccessTypes = false
        this.accessTypes = resp
      }
    )
    // Get User
    this.$store.dispatch(
      GET_USER
    ).then(
      resp => {
        this.user = Object.assign(this.user, resp)
        this.user.phoneWithout33 = this.user.phone.replace('+33', '0')
        if (resp.dob !== null) {
          if (!!window.MSInputMethodContext && !!document.documentMode) {
            // IE
          }else{
            this.user.dob = new Date(resp.dob)
          }
        }
        
        if (this.user.company !== null) {
          this.disabled.company = true
        }
        if (this.user.tax_id !== null) {
          this.disabled.tax_id = true
        }
      }
    )
    // Get Settings
    this.$store.dispatch(
      GET_SETTING, {key: "commission_percentage_jsmb"}
    ).then(
      resp => {
        this.commissionPercentageJSMB = resp.value
      }
    )
    if (localStorage.getItem('stepTwo') !== null) {
      var stepTwo = JSON.parse(localStorage.getItem('stepTwo'))
      this.spaces = stepTwo.spaces
    }
    if (localStorage.getItem('stepFour') !== null) {
      this.stepFour = JSON.parse(localStorage.getItem('stepFour'))
    }
    if (localStorage.getItem('stepOne') !== null) {
      var stepOne = JSON.parse(localStorage.getItem('stepOne'))
      // Get Cales
      this.$store.dispatch(
        GET_CALE, {longitude: stepOne.longitude, latitude: stepOne.latitude}
      ).then(
        resp => {
          this.nearest_hold = Math.round(resp.proximite)
          this.spaces.forEach(space => {
            // Prix de Base
            let priceBase = 0
            if (space.length <= 5) {
              priceBase = 49
            }else if (space.length <= 10) {
              priceBase = 79
            }else if (space.length > 10) {
              priceBase = 99
            }
            // Rayon
            let priceRay = 0
            if (0 <= this.nearest_hold && this.nearest_hold < 5) {
              priceRay = 90
            }else if (5 <= this.nearest_hold && this.nearest_hold < 10) {
              priceRay = 65
            }else if (10 <= this.nearest_hold && this.nearest_hold < 30) {
              priceRay = 40
            }else if (30 <= this.nearest_hold && this.nearest_hold < 50) {
              priceRay = 25
            }
            space.priceMonthRecommended = new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format( priceBase + priceRay + (space.options.length * 5) )
            space.priceDayRecommended = new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format( (priceBase + priceRay + (space.options.length * 5)) / 30 )
            space.priceUser = Math.round(((priceBase + priceRay + (space.options.length * 5)) / 30) * 100) / 100
          });
        }
      )
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="css">
  div[class^='jsmb-type']{
      margin-bottom: 3rem;
  }
  h2 {
      font-weight: bold;
      margin-bottom: 3rem;
  }
  h3 {
    margin-bottom: 1rem;
  }
  input[type=radio],
  input[type=checkbox] {
    border: 1px solid #df5051;
    padding: 0.5em;
    -webkit-appearance: none;
    margin-bottom: 0;
  }
  input[type=radio]:checked,
  input[type=checkbox]:checked {
    background-size: 9px 9px;
    background-color: #df5051;
  }
  input[type=radio]:focus,
  input[type=checkbox]:focus {
    outline-color: none;
  }
  .checkbox,
  .radio {
    font-size:0.85rem;
    margin-bottom: 0.5rem;
  }
  .jmsb-place-container {
    margin-bottom: 2.5rem;
  }
  .jsmb-place {
    background:#fff;
    padding: 1.5rem 1rem;
    margin-bottom: 1rem;
  }
  .has-text-underline {
    text-decoration: underline;
  }
  .place-input {
    text-align: center;
    background-color: white;
    color: #363636;
    width: max-content;
    border-radius: 4px;
    position: relative;
  }
  .place-input input{
    border:1px solid #dbdbdb;
    border-left:none;
    border-right:none;
    padding: 0.5rem .5rem;
    margin-bottom: 0;
    text-align: center
  }
  .place-input:after {
    content:"€ / jour";
    display: block;
    position: absolute;
    right:-2.7rem;
    top:50%;
    transform: translateY(-50%);
    font-size: 0.8rem
  }
  .push-bottom {
    margin-top: auto;
  }
  .jsmb-type-conditions label {
    font-weight: normal;
  }
  input[type="tel"]{
    background: transparent;
    box-shadow: none;
  }
  /* @media screen and (max-width:789px) { */
  @media screen and (max-width:1087px) {
    .centror {
      justify-content: center;
      display: flex;
      margin: 1rem 0
    }
  }
  .button.rmvm {
    margin: 0;
  }
  .button.rmvm.br {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .button.rmvm.bl {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  input[type=number]::-webkit-inner-spin-button, 
  input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
    margin: 0; 
  }
</style>

<style  lang="css">
  .jmsb-annonce-photo-container .upload .upload-draggable {
    border: 0px !important;
  }
  .jmsb-annonce-photo-container .has-addons {
    display:flex;
    flex-direction:column;
    align-items:center;
  }
  .jmsb-annonce-photo-container .file-name {
    border-width:0px;
    border-radius:3px;
    font-size:0.8rem;
    color:#fff;
    width:8rem;
    text-align: center;
    background:#14acc2;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .jmsb-annonce-photo-container .field.has-addons .control:first-child .button {
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
  }
</style>
