<template>
    <div>
      <div class="jsmb-wizard tabs is-small is-centered">
          <ul class="is-uppercase">
            <li :class="{ 'active' : $parent.step >= 0}"><a>informations</a></li>
            <li :class="{ 'active' : $parent.step >= 1}"><a>Détails</a></li>
            <li :class="{ 'active' : $parent.step >= 2}"><a>Photos</a></li>
            <li :class="{ 'active' : $parent.step >= 3}"><a>Conditions</a></li>
          </ul>
      </div>

    </div>
</template>
<script>

export default {
  name: 'Navigation',

    data () {
    return {

    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="css">
  .jsmb-wizard {
    /* padding: 0 1rem 1rem;
    margin-top: 1rem;
    margin-bottom: 0;
    background: #f2f5fb; */
    background: linear-gradient(to bottom, #ffffff 0%,#ffffff 1rem,#f2f5fb 1rem,#f2f5fb 1rem,#f2f5fb 100%);
  }
  .jsmb-wizard:before {
    content: "";
    position: absolute;
    left: -100%;
    background: linear-gradient(to bottom, #ffffff 0%,#ffffff 1rem,#f2f5fb 1rem,#f2f5fb 1rem,#f2f5fb 100%);
    width: 100%;
    height: 100%;
    top: 0;
}
  .jsmb-header-container {
    background: #f2f5fb;
  }
  .tabs li{
    border-top:1rem solid #fff;
  }
  .jsmb-wizard.tabs li.active a {
    border: none;
  }
  .jsmb-wizard.tabs li.active {
    position: relative;
  }
  .jsmb-wizard.tabs li.active:before {
    content: "";
    position: absolute;
    top: -1rem;
    background: #0ec0b6;
    height: 1rem;
    width: 100%;
  }
  .jsmb-wizard.tabs ul,
  .jsmb-wizard.tabs li:not(.active) a {
    border: none;
    color:#959595;
  }
  .jsmb-wizard.tabs li a {
    padding:1.5rem 1.5vw;
    font-size:0.9rem;

  }
  @media screen and (max-width: 768px) {
    .jsmb-wizard.tabs li a {
        font-size:0.7rem;
    }
  }

</style>